// extracted by mini-css-extract-plugin
export var accordion = "Projects-module--accordion--+wDna";
export var button = "Projects-module--button--E889X";
export var categoryName = "Projects-module--categoryName--cOKaD";
export var categorySection = "Projects-module--categorySection--XU+O6";
export var custom = "Projects-module--custom--aBLjn";
export var infoBanner = "Projects-module--infoBanner--kiAh6";
export var more = "Projects-module--more--dc4Im";
export var project = "Projects-module--project--B-Om+";
export var projectSearch = "Projects-module--projectSearch--YevRD";
export var searchParent = "Projects-module--searchParent--tkCvd";
export var searchSubtitle = "Projects-module--searchSubtitle--wvfWM";
export var skillPill = "Projects-module--skillPill--xjC+N";