import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import ProjectCard from '@components/ProjectCard/index';
import ProjectCategories from '@components/ProjectCategories/index';
import ProjectContribute from '@components/ProjectContribute/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import db from '../../data/db.json';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Projects.module.scss';
import { matchSorter } from 'match-sorter';
import { debounce } from 'lodash';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

const deleteObj = (data, column, search) => {
  let result = data.filter((m) => m[column] !== search);

  return result;
};

export default function Projects({ data, ...props }) {
  const isMobile = useResizer();
  const sale = data.strapiSale.SaleID;
  const state = useContext(GlobalStateContext);

  const projectCardImages = data.projectCardImages.nodes;
  let categories = [];
  let meList = [];
  let uniqueMeList = [];
  let projects = db['projects'];

  const ref = useRef();
  Object.keys(projects).map((category, idx) => {
    meList = [...meList, ...projects[category].meList];

    let categorySanitized = category
      .split('-')
      .join(' ')
      .split(' ')
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ')
      .split(' ')
      .join('-')
      .toLowerCase();
    let temp = {
      meCategoryId: projects[category].meCategoryId,
      category: categorySanitized,
      image: getProjectIcon(categorySanitized),
      url: `/projects/category/${category}-projects`,
    };
    categories.push(temp);
  });
  for (var i = 0; i < meList.length; i++) {
    if (uniqueMeList.indexOf(meList[i].meId) === -1) {
      uniqueMeList.push(meList[i]);
    }
  }
  uniqueMeList = uniqueMeList.filter(
    (a, i) => uniqueMeList.findIndex((b) => b.meId === a.meId) === i,
  ); // unique by id
  // uniqueMeList = deleteObj(
  //   uniqueMeList,
  //   'meSlugUrl',
  //   'python-stackoverflow-auto-search',
  // );
  // uniqueMeList = deleteObj(uniqueMeList, 'meSlugUrl', 'python-discord-bot');

  // console.log(categories);
  function getProjectIcon(category) {
    switch (category) {
      case 'java':
      case 'app-development':
      case 'machine-learning':
      case 'react':
      case 'web-development':
      case 'bash':
      case 'javascript':
      case 'python':
        return `projects/CategoryCards/${category}.png`;
      default:
        return `projects/CategoryCards/generic.png`;
    }
  }

  const debounceQuery = useCallback(
    debounce(async (payload, email) => {
      if (payload.length > 3) {
        fetch(
          `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=project-searches&email=${email}&query=${payload}`,
        );
      }
      updateQuery(payload);
    }, 800),
    [],
  );
  // const fuse = new Fuse(uniqueMeList, {
  // keys: [
  //   'meFocus',
  //   'meTitle',
  //   'meDescription',
  //   'meSkills',
  //   'meKeywords',
  //   'mePreRequisite',
  //   'meSummary',
  //   'meCategory.meCategoryId',
  //   'meLanguageType',
  //   'meId',
  //   'meAuthor',
  //   'meCollaborators',
  // ],
  //   includeScore: true,
  //   threshold: 0.4,
  //   // includeMatches: true,
  // });

  const [query, updateQuery] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  function getDifficulty(level) {
    let response = '';
    switch (level) {
      case 'ME_DIFFICULTY_TYPE_BEGINNER':
        response = 'Beginner';
        break;
      case 'ME_DIFFICULTY_TYPE_INTERMEDIATE':
        response = 'Intermediate';
        break;
      case 'ME_DIFFICULTY_TYPE_ADVANCED':
        response = 'Advanced';
        break;
      default:
        response = 'Beginner';
    }
    return response;
  }

  useEffect(() => {
    // let results = fuse.search(query);
    // setFilteredProjects(results);
    // console.log(results);

    let results = matchSorter(uniqueMeList, query, {
      keys: [
        'meFocus',
        'meTitle',
        'meDescription',
        'meSkills',
        'meKeywords',
        'mePreRequisite',
        'meSummary',
        'meCategory.meCategoryId',
        'meLanguageType',
        'meId',
        'meAuthor',
        'meCollaborators',
      ],
    });
    setFilteredProjects(results);

    if (query.length > 2) {
      // handleChangeDebounce(query);
    }
  }, [query]);

  function onSearch(e, email) {
    // e.persist();
    e.preventDefault();
    debounceQuery(e.target.value, email);
    // updateQuery(e.target.value);
  }

  // const handleChangeDebounce = useCallback(
  //   debounce(function (msg) {
  //     fetch(
  //       `https://script.google.com/macros/s/AKfycbzqPTNEmwDfmaaSia0A2qg_xoJXDtXN1SN2XnOhacBTHDVPz4I9vVL1kA/exec?sheet=Searches&query=${msg}`,
  //     );
  //   }, 2000),
  //   [],
  // );

  // const handleChangeDebounce = d;

  return (
    <>
      <SEO
        canonicalUrl={'https://crio.do/projects'}
        title="Crio Projects Hub"
        description="Find interesting project ideas for CSE and get started with an execution plan"
      ></SEO>
      {/* <GatsbySeo
        title="Crio Projects Hub"
        description="Find interesting project ideas for CSE and get started with an execution plan"
        canonical="https://www.crio.do/projects"
        openGraph={{
          type: "website",
          title: "Crio Projects Hub",
          site_name: "Crio Projects Hub",
          locale: "en_IN",
          url: "https://www.crio.do/projects",
          description:
            "Find interesting project ideas for CSE and get started with an execution plan",
          images: [
            {
              url:
                "https://crio-assets.s3.ap-south-1.amazonaws.com/Project.png",
              width: 800,
              height: 827,
              alt: "Crio.Do",
            },
          ],
        }}
        twitter={{
          handle: "@crio_do",
          site: "@crio_do",
          cardType: "summary_large_image",
        }}
      /> */}
      {/* <div className="fixed-header">
        {' '}
        <PromotionBanner sale={sale} location={'PROJECTS'}></PromotionBanner>
        <Header></Header>{' '}
      </div> */}
      <Navigation />
      <div
        style={{
          background: '#F3F1EE',
        }}
      >
        <div className="container  ">
          <section className="pt-40 pb-10  md:pt-36 ">
            <div className="container  ">
              <div className="row h-full">
                <div className="col-12 col-lg-8 mb-5 flex  flex-col  justify-center  p-0 md:text-left  ">
                  <div className="mb-4">
                    <div className="w-max rounded-md bg-crio-yellow-500 p-1 pl-3 pr-3 text-sm font-semibold">
                      Completely free • Community Driven{' '}
                    </div>
                    <h1 style={{ color: '#075353' }} className="">
                      Introducing
                      <div className="md:hidden"></div> Crio Projects Hub
                    </h1>
                    <p className="mb-1">
                      Find interesting mini projects for CSE and get started
                      with an execution plan
                    </p>
                    <h5 style={{ color: '#999' }}>#BuildWhatYouLove</h5>
                  </div>
                  <div className="mb-2 flex justify-start">
                    <div className={styles.searchParent}>
                      {' '}
                      <input
                        className={styles.projectSearch}
                        placeholder="Search for projects..."
                        type="text"
                        onChange={(e) => {
                          onSearch(e, state.email);
                        }}
                        ref={ref}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length,
                          )
                        }
                      ></input>{' '}
                      {query.length == 0 ? (
                        <FontAwesomeIcon
                          icon={['far', 'search']}
                          style={{
                            color: '#74715D',
                          }}
                        />
                      ) : (
                        <div
                          onClick={() => {
                            ref.current.value = '';
                            updateQuery('');
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-danger cursor-pointer "
                            icon={['far', 'times']}
                          />
                        </div>
                      )}{' '}
                    </div>
                  </div>{' '}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '-20px',
                      left: '0',
                      right: '0',
                    }}
                  >
                    {query.length > 1 && filteredProjects.length == 0 && (
                      <div className={` bodyText2 font-light text-red-600 `}>
                        No projects found
                      </div>
                    )}
                    {query.length > 0 && filteredProjects.length != 0 && (
                      <div
                        className={`bodyText2 font-light  ${styles.searchSubtitle}`}
                      >
                        Showing{' '}
                        <span className="font-normal text-green-600">
                          {filteredProjects.length}
                        </span>{' '}
                        projects.
                      </div>
                    )}
                  </div>
                </div>
                {((filteredProjects.length === 0 && query.length <= 1) ||
                  !isMobile) && (
                  <div className="   col-12 col-lg-4 mb-5 flex  items-center justify-center md:mb-0 md:justify-end">
                    <StaticImage
                      loading="lazy"
                      src="../../images/projects/HeroImage.png"
                      placeholder="blurred"
                      alt="Crio Projects"
                      style={{ width: '80%' }}
                    />
                    {/* <Image
                      filename="projects/HeroImage.png"
                      style={{ width: '80%' }}
                    ></Image> */}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      {filteredProjects.length > 0 && query.length > 0 && (
        <div className={` pt-10 `}>
          <div className="container   pb-20">
            <div className="row flex flex-wrap text-sm">
              {filteredProjects.map((project, idx) => (
                <div
                  className="col-lg-6 mb-6"
                  key={project.meId}
                  id={project.meId}
                >
                  <div className="h-full border">
                    <ProjectCard project={project}></ProjectCard>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <ProjectCategories
        categories={categories}
        images={projectCardImages}
      ></ProjectCategories>{' '}
      <ProjectContribute></ProjectContribute>
      <div className={`pt-10 pb-10 `}>
        <div className="container   pb-20">
          <h3 className="mb-8">FAQs</h3>
          <div className="col-12 flex flex-col items-center justify-center p-0">
            <div className="container   p-0 " style={{ marginTop: '-30px' }}>
              <div className={`${styles.accordion} custom mb-4`}>
                <ul>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      How do I start a project?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        <ul>
                          <li>
                            Before starting a project, be sure to select a
                            project that
                            <ol type="a">
                              <li> will add value to your career goals.</li>
                              <li> matches your programming interests.</li>
                            </ol>
                          </li>{' '}
                          <li>
                            Explore the mini projects that are available and
                            click on a project that appeals to you.
                          </li>{' '}
                          <li>
                            Go through the project overview to understand the
                            scope and prerequisites before you start a project.
                          </li>{' '}
                          <li>
                            Once you have found a mini project that meets the
                            above 2 criteria, go ahead and click Start Now to
                            begin working as per the laid out plan.
                          </li>
                        </ul>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      What projects can I do with Python?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        Search for these projects if you are looking for python
                        project ideas to test your python skills:<br></br>
                        <ol type="1">
                          <li>Cricket alerts in Python</li>{' '}
                          <li>WhatsApp APK for stickers</li>{' '}
                          <li>Discord bot for stocks</li>{' '}
                          <li>Web scraping Facebook bot</li>{' '}
                          <li>Python interpreter</li>{' '}
                        </ol>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      What projects can I do with Javascript?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        {' '}
                        Search for these projects if you are looking for html,
                        css, javascript projects or web development projects to
                        hone your frontend development skills:
                        <br></br>
                        <ol type="1">
                          <li>Online Code Editor (JQuery)</li>{' '}
                          <li>Online Code Editor (React)</li>{' '}
                          <li>Slack clone using React</li>{' '}
                        </ol>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Is Python better than Javascript?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        If you are new to programming and are not particularly
                        interested in frontend, Python is a great option for you
                        to start with. Learning python will come in handy for
                        you at some point or the other.<br></br>
                        <br></br>If you are particularly interested in building
                        websites, you should focus on HTML, CSS, Javascript
                        projects to deepen your skills.
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      How do I decide what project to do first?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        <ul>
                          <li>
                            Start with something that is beginner friendly :)
                          </li>{' '}
                          <li>
                            {' '}
                            It is okay to choose any project as long as you can
                            learn/apply something.
                          </li>{' '}
                          <li>
                            Read through the project overview of all projects
                            and pick one that is interesting to you and covers
                            the skills you want to explore.
                          </li>
                        </ul>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Why should I do a project?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        Mini projects push you to dig deeper and find solutions
                        on your own. Retention of skills is higher when you
                        practically apply yourself to do something and figure
                        things out by yourself. This is why interviewers always
                        ask many questions related to your project to see how
                        deeply you have explored the concepts and skills in the
                        project.
                        <br></br>
                        <br></br>Read more:{' '}
                        <a
                          href="https://www.crio.do/blog/mini-projects-for-computer-science-engineers/?utm_source=crio-website-faq"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mini projects for computer science engineers
                        </a>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      How long should a final year project be?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        <ul>
                          <li>3 to 4 months of effort</li>{' '}
                          <li>100+ hours of coding (at least)</li>
                        </ul>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      What should I do for a final year project?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        Final year projects for computer engineering should have
                        at least 1000 lines of code (ignoring comments,
                        whitelines when you finish your final year project).{' '}
                        <br></br>
                        <br></br>Do a project that is meaningful and helps you
                        learn better by applying your knowledge. <br></br>
                        <br></br> Refrain from choosing a random mix of IEEE
                        project topics for computer science. Opportunity to
                        apply your knowledge and take your skills to the next
                        level is very low in such projects.
                        <br></br>
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Should I do projects alone or with a group?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        Group projects with max 2-3 members are reasonable so
                        that every member has a significant role to play in the
                        project. Pick partners who share your thirst to learn
                        and build something from the ground up.
                      </div>
                    </p>
                  </li>
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Are these projects only for students?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        No. The mini projects are equally valuable and a good
                        learning experience for working professionals as well.
                        In fact, if you already have the experience in{' '}
                        <strong>software development</strong>, you will be able
                        to take the projects to the next level by building
                        features that make it even better.
                      </div>
                    </p>
                  </li>{' '}
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Are these free projects?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        Yes. You can pick how many ever projects you like and
                        start working on them.
                      </div>
                    </p>
                  </li>{' '}
                  <li>
                    <input type="checkbox" defaultChecked />
                    <i />
                    <div className="subtitle1 font-light ">
                      Are these online projects or can I download projects from
                      here?
                    </div>
                    <p className="font-light">
                      <div className={`pt-2`}>
                        No. Majority of the time, it is tempting to download a
                        project since there aren’t clear instructions on how to
                        go about working on them. <br></br>
                        <br></br>Crio Projects Hub is a collection of computer
                        science projects that you can pick up and complete on
                        your own with guided instructions.
                        <br></br>
                        <br></br>Once you have selected a project you want to
                        work on, you will be doing it in your own local work
                        environment. <br></br>
                        <br></br> If you get stuck during your project, you can
                        always raise your query on{' '}
                        <a
                          href="https://forum.crio.do/c/project/408/?utm_source=crio-website-faq"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Crio Forum
                        </a>{' '}
                        and get answers from the Crio Community to move forward
                        with your project.
                      </div>
                    </p>
                  </li>{' '}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterV2 />
      {/* <Footer></Footer> */}
    </>
  );
}

export const pageQuery = graphql`
  query {
    strapiSale {
      ...strapiSaleFragment
    }

    projectCardImages: allFile(
      filter: { relativePath: { regex: "images/projects/CategoryCards/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 450
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
